import { stores, ViewlioWholesaleStoreConfig } from './stores'

export { stores } from './stores'
export { paths } from './paths'
export type { ViewlioWholesaleStore, ViewlioWholesaleStoreConfig } from './stores'

export const domains = [
  'wholesale-$iso.dev.juul.com',
  '$iso.wholesale.qa1.viewlio.app',
  '$iso.wholesale.staging.viewlio.app',
  '$iso.wholesale.stage.viewlio.app',
  '$iso.wholesale.preprod.viewlio.app',
  '$iso.wholesale.production.viewlio.app',
]

export const storeLocales =
  Object.values(stores)
    .flatMap((store: ViewlioWholesaleStoreConfig) => store.locales)

