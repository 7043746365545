import getConfig from 'next/config'

import { StoreType } from '../types'

import { ViewlioEnv } from './index'

export type ViewlioStore =
  | 'juul-ca'
  | 'juul-it'
  | 'juul-uk'
  | 'juul-us'

export enum ViewlioCountryCode {
  ca = 'ca',
  it = 'it',
  ua = 'ua',
  uk = 'uk',
  us = 'us',
}

export enum DateParts {
  Day,
  Month,
  Year
}

export type ViewlioStoreConfig = {
  ageGate: {
    geolocation: boolean
    shouldTrackAgeGateEvents: boolean
  }
  ageVerification?: {
    dateFormat: DateParts[]
    stateSelector: boolean
  }
  baseUrl: Record<ViewlioEnv, string>
  contentful: {
    spaceId: string
  } & ContentfulAccessTokens
  countryCode: string
  defaultBaseUrl: string
  dmUnsubscribe?: {
    stateSelector: boolean
  }
  ecommerceEnabled?: boolean
  iso: string
  label: string
  locales: string[]
  storeLocator: {
    filters: string[]
  }
  type: StoreType
  viewlioOrigins: Record<ViewlioEnv, string>
}

type ContentfulAccessTokens = {
  accessToken: string
  previewAccessToken: string
}

export const contentfulAccessTokens =
  getConfig()?.serverRuntimeConfig?.contentfulAccessTokens || {}

// Used to force target Juulio Staging.
// Note: running `yarn dev:staging` will set this environment variable for you.
const USE_STAGING = Boolean(process.env.NEXT_PUBLIC_USE_STAGING)

// When running locally, we use a local Juulio server by default.
// Make sure you run your local juulio server on the right port.
const DEFAULT_JUULIO_BASE_URL = USE_STAGING
  ? 'https://stage.juul.com'
  : 'http://dev.juul.com:3001'

// You can set the NEXT_PUBLIC_JUULIO_BASE_URL to override
// the default juulio base URL.
const JUULIO_BASE_URL = process.env.NEXT_PUBLIC_JUULIO_BASE_URL

// Compute the final development base URL using the country code prefix.
// For example,"http://dev.juul.com:3000" -> "http://us.dev.juul.com:3000"
const getDevelopmentBaseUrl = (countryCode: ViewlioCountryCode) => {
  const baseUrl = new URL(JUULIO_BASE_URL || DEFAULT_JUULIO_BASE_URL)
  baseUrl.host = `${countryCode}.${baseUrl.host}`

  return baseUrl.toString()
}

const viewlioOrigins = {
  ci: 'https://staging.viewlio.app',
  development: `http://us.dev.juul.com:${process.env.PORT || 3000}`,
  preprod: 'https://preprod.viewlio.app',
  preview: 'https://staging.viewlio.app',
  production: 'https://viewlio-juullabs.vercel.app',
  qa1: 'https://viewlio-git-qa1-juullabs.vercel.app',
  qa2: 'https://viewlio-git-qa2-juullabs.vercel.app',
  qa3: 'https://viewlio-git-qa3-juullabs.vercel.app',
  qa4: 'https://viewlio-git-qa4-juullabs.vercel.app',
  qa5: 'https://viewlio-git-qa5-juullabs.vercel.app',
  staging: 'https://staging.viewlio.app',
}

export const stores: Record<ViewlioStore, ViewlioStoreConfig> = {
  'juul-ca': {
    ageGate: {
      geolocation: true,
      shouldTrackAgeGateEvents: false,
    },
    ageVerification: {
      dateFormat: [DateParts.Year, DateParts.Month, DateParts.Day],
      stateSelector: true,
    },
    baseUrl: {
      ci: 'https://ca.stage.juul.com',
      development: getDevelopmentBaseUrl(ViewlioCountryCode['ca']),
      preprod: 'https://ca.preprod.juul.com',
      preview: 'https://ca.stage.juul.com',
      production: 'https://www.juul.ca',
      qa1: 'https://ca.qa1.juul-dev.com',
      qa2: 'https://ca.qa2.juul-dev.com',
      qa3: 'https://ca.qa3.juul-dev.com',
      qa4: 'https://ca.qa4.juul-dev.com',
      qa5: 'https://ca.qa5.juul-dev.com',
      staging: 'https://ca.stage.juul.com',
    },
    contentful: {
      ...contentfulAccessTokens['juul-ca'],
      spaceId: 'vyk520m9px5z',
    },
    countryCode: 'CA',
    defaultBaseUrl: 'http://ca.dev.juul.com',
    dmUnsubscribe: {
      stateSelector: true,
    },
    ecommerceEnabled: true,
    iso: 'ca',
    label: 'Canada',
    locales: [
      'en-CA',
      'fr-CA',
    ],
    storeLocator: {
      filters: [],
    },
    type: StoreType.Consumer,
    viewlioOrigins,
  },
  'juul-it': {
    ageGate: {
      geolocation: false,
      shouldTrackAgeGateEvents: false,
    },
    ageVerification: {
      dateFormat: [DateParts.Day, DateParts.Month, DateParts.Year],
      stateSelector: true,
    },
    baseUrl: {
      ci: 'https://it.stage.juul.com',
      development: getDevelopmentBaseUrl(ViewlioCountryCode['it']),
      preprod: 'https://it.preprod.juul.com',
      preview: 'https://it.stage.juul.com',
      production: 'https://www.juullabs.it',
      qa1: 'https://it.qa1.juul-dev.com',
      qa2: 'https://it.qa2.juul-dev.com',
      qa3: 'https://it.qa3.juul-dev.com',
      qa4: 'https://it.qa4.juul-dev.com',
      qa5: 'https://it.qa5.juul-dev.com',
      staging: 'https://it.stage.juul.com',
    },
    contentful: {
      ...contentfulAccessTokens['juul-it'],
      spaceId: '0uw6dq9w46yy',
    },
    countryCode: 'IT',
    defaultBaseUrl: 'http://it.dev.juul.com',
    ecommerceEnabled: true,
    iso: 'it',
    label: 'Italy',
    locales: [
      'it-IT',
    ],
    storeLocator: {
      filters: [],
    },
    type: StoreType.Consumer,
    viewlioOrigins,
  },
  'juul-uk': {
    ageGate: {
      geolocation: false,
      shouldTrackAgeGateEvents: false,
    },
    ageVerification: {
      dateFormat: [DateParts.Day, DateParts.Month, DateParts.Year],
      stateSelector: false,
    },
    baseUrl: {
      ci: 'https://uk.stage.juul.com',
      development: getDevelopmentBaseUrl(ViewlioCountryCode['uk']),
      preprod: 'https://uk.preprod.juul.com',
      preview: 'https://uk.stage.juul.com',
      production: 'https://www.juul.co.uk',
      qa1: 'https://uk.qa1.juul-dev.com',
      qa2: 'https://uk.qa2.juul-dev.com',
      qa3: 'https://uk.qa3.juul-dev.com',
      qa4: 'https://uk.qa4.juul-dev.com',
      qa5: 'https://uk.qa5.juul-dev.com',
      staging: 'https://uk.stage.juul.com',
    },
    contentful: {
      ...contentfulAccessTokens['juul-uk'],
      spaceId: 'ho2j2jvaj4rt',
    },
    countryCode: 'GB',
    defaultBaseUrl: 'http://uk.dev.juul.com',
    dmUnsubscribe: {
      stateSelector: false,
    },
    ecommerceEnabled: true,
    iso: 'uk',
    label: 'United Kingdom',
    locales: [
      'en-GB',
    ],
    storeLocator: {
      filters: [
        'juulRetailStore',
      ],
    },
    type: StoreType.Consumer,
    viewlioOrigins,
  },
  'juul-us': {
    ageGate: {
      geolocation: true,
      shouldTrackAgeGateEvents: true,
    },
    ageVerification: {
      dateFormat: [DateParts.Month, DateParts.Day, DateParts.Year],
      stateSelector: true,
    },
    baseUrl: {
      ci: 'https://us.stage.juul.com',
      development: getDevelopmentBaseUrl(ViewlioCountryCode['us']),
      preprod: 'https://us.preprod.juul.com',
      preview: 'https://us.stage.juul.com',
      production: 'https://www.juul.com',
      qa1: 'https://us.qa1.juul-dev.com',
      qa2: 'https://us.qa2.juul-dev.com',
      qa3: 'https://us.qa3.juul-dev.com',
      qa4: 'https://us.qa4.juul-dev.com',
      qa5: 'https://us.qa5.juul-dev.com',
      staging: 'https://us.stage.juul.com',
    },
    contentful: {
      ...contentfulAccessTokens['juul-us'],
      spaceId: 'tc11z0kp0vll',
    },
    countryCode: 'US',
    defaultBaseUrl: 'http://us.dev.juul.com',
    dmUnsubscribe: {
      stateSelector: true,
    },
    ecommerceEnabled: true,
    iso: 'us',
    label: 'United States',
    locales: [
      'en-US',
    ],
    storeLocator: {
      filters: [
        'inStoreWarranty',
      ],
    },
    type: StoreType.Consumer,
    viewlioOrigins,
  },
}
