import { StoreType } from '@viewlio/config/src/types'
import { viewlioConfig } from '@viewlio/config/src/viewlioConfig'
import { stores as wholesaleStores } from '@viewlio/config/src/wholesale'

import { clearLocale } from './clearLocale'

export const getStoreByTypeAndLocale = (
  storeType: StoreType, locale: string,
) => {
  const allStores = {
    ...viewlioConfig.stores,
    ...wholesaleStores,
  }
  return Object.entries(allStores).find(([_store, { locales, type }]) =>
    type === storeType &&
    (locales.includes(locale) || locales.includes(clearLocale(locale))),
  )
}
