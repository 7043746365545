import { LoadingMeta } from '@viewlio/types/src'

import { initialLoadingMeta } from './initialLoadingMeta'

export const getErrorMeta = (error: string): LoadingMeta => ({
  ...initialLoadingMeta,
  error: true,
  errorMessage: error,
  loaded: true,
})
