/* eslint-disable no-restricted-imports, @typescript-eslint/ban-types */
import {
  camelizeKeys as humpsCamelizeKeys,
  decamelizeKeys as humpsDecamelizeKeys,
} from 'humps'

/**
 * Those method was created to skip converting keys containing only
 * uppercase letters or numbers
 *
 * more info here: https://www.npmjs.com/package/humps#converting-object-keys
 */

export const camelizeKeys = (obj: object): any => humpsCamelizeKeys(
  obj,
  (key, convert) => /^[A-Z0-9_]+$/.test(key) ? key : convert(key),
)

export const decamelizeKeys = (obj: object): any => humpsDecamelizeKeys(
  obj,
  (key, convert) => /^[A-Z0-9_]+$/.test(key) ? key : convert(key),
)
